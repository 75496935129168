import type { NextPageContext } from 'next';

export interface IScreenInitialProps {
  routeEnv: TRouteEnv;
}

export default function getScreenInitialProps<P = Record<string | number | symbol, any>, IP = P>(
  getInitialProps: (context: NextPageContext) => IP | Promise<IP>
) {
  return async (context: NextPageContext) => {
    const props = await getInitialProps(context);
    return {
      ...(props ?? {}),
      routeEnv: getRouteEnv(context),
    };
  };
}

export type TRouteEnv = 'server' | 'client';

function getRouteEnv(ctx: NextPageContext): TRouteEnv {
  return ctx.req ? 'server' : 'client';
}
