import React, { useEffect } from 'react';

import { NextPageContext } from 'next';
import dynamic from 'next/dynamic';

import useSystem from 'coreSrc/base/systemManager/useSystem';

import { parseCookie } from '../../../coreSrc/core/js/utils/cookie';
import getScreenInitialProps from '../../../hostSrc/js/getScreenInitialProps';
import { SEO, isSeoUpdatable } from '../../../hostSrc/pages/_app/useSeo';
import RichgoLdJSON from '../../../mapTestSrc/mode/maps/richgoMap/RichgoLdJSON';

const MobilePage_UISet_RichgoMap_Mobile_V2 = dynamic(
  () => import('mapTestSrc/mode/maps/richgoMap/ui/mobile/MobilePage_UISet_RichgoMap_Mobile_V2'),
  {
    ssr: false,
  }
);

function MapPage() {
  const sys = useSystem();
  useEffect(() => {
    const inviteCode = new URLSearchParams(location.search).get('inviteCode');
    if (!inviteCode) return;
    sessionStorage.setItem('inviteCode', inviteCode);
  }, []);
  useEffect(() => {
    if (sys.detector.isMobile) return;
    sys.router.replace('pc');
  }, []);
  if (!sys.detector.isMobile) return <div />;
  return (
    <>
      <RichgoLdJSON />
      <MobilePage_UISet_RichgoMap_Mobile_V2 />
    </>
  );
}

export default MapPage;

MapPage.getInitialProps = getScreenInitialProps(async (ctx: NextPageContext) => {
  const seo = {
    url: 'https://m.richgo.ai/realty/richgoMap',
    title: SEO.title,
    description: SEO.description,
    customKeywords: SEO.keywords,
  };
  if (ctx.req) {
    //서버사이드
    const accessToken = parseCookie(ctx.req.headers.cookie).RICHGO_API_ACCESS_TOKEN;

    try {
      console.log('seo', seo);
      return { seo, accessToken };
    } catch (error) {
      console.log('error', error);
      {
        console.log('여기냐', error, ctx.req.url);
      }
      if (error instanceof Response) {
        if (error.status === 404)
          return {
            error: { type: 'not-found' },
          };
      }
    }
  }

  if (isSeoUpdatable(ctx)) {
    console.log('seo2', seo);
    return { seo };
  }

  return {};
});
