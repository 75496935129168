import React from 'react';

import Head from 'next/head';

import { SEO } from '../../../../hostSrc/pages/_app/useSeo';

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://m.richgo.ai/realty/richgoMap',
  name: '리치고',
  alternateName: SEO.title,
  description: SEO.description,

  publisher: {
    '@type': 'Organization',
    name: '리치고',
    logo: {
      '@type': 'ImageObject',
      url: 'https://m.richgo.ai/logo_for_search.png',
    },
  },
  sameAs: ['https://expert.richgo.ai/', 'https://www.youtube.com/channel/UC3nsb3SxlRrJQ9egkKNnlfg'],
  // potentialAction: {
  //   '@type': 'DownloadAction',
  //   target: [
  //     {
  //       '@type': 'EntryPoint',
  //       urlTemplate: 'https://richgo.page.link/u9dy',
  //       actionPlatform: 'http://schema.org/AndroidPlatform',
  //     },
  //     {
  //       '@type': 'EntryPoint',
  //       urlTemplate: 'https://richgo.page.link/u9dy',
  //       actionPlatform: 'http://schema.org/iOSPlatform',
  //     },
  //   ],
  // },
};
const jsonLd2 = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: '리치고',
  url: 'https://m.richgo.ai/realty/richgoMap',
  logo: 'https://m.richgo.ai/logo_for_search.png',
  // contactPoint: {
  //   '@type': 'ContactPoint',
  //   // telephone: '',
  //   contactType: 'Customer Service',
  //   areaServed: 'KR',
  //   availableLanguage: 'Korean',
  // },
  sameAs: ['https://expert.richgo.ai/', 'https://www.youtube.com/channel/UC3nsb3SxlRrJQ9egkKNnlfg'],
};
const RichgoLdJSON = () => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd2) }}
      />
    </Head>
  );
};
export default RichgoLdJSON;
